import { MenuOutlined } from '@ant-design/icons';
import { Card, Typography as T } from 'antd';
import React from 'react';
import i18n from './i18n';
import './index.scss';

interface IProps {
  locale: string
}

const deliverySide = (props: IProps) => {
  const lang = i18n[props.locale]
  return (
    <div className="sge-right-side sg-deliver-seller">
      <Card
        className="t-center"
        size="small"
        title={
          <T.Text>
            {lang.safetyShopping} <br />
            <T.Text type={'warning'}>SUNNY GARDEN</T.Text>
          </T.Text>
        }
      >
        <ul className="t-left">
          <li>
            <div className="icon">
              <MenuOutlined />
            </div>
            <div className="des">
              <T.Text>
                {lang.oneToOneExchange}
                {/* <Link to="/">Chi tiết</Link> */}
              </T.Text>
            </div>
          </li>
          {/* <li>
            <div className="icon">
              <MenuOutlined />
            </div>
            <div className="des">
              <T.Text>Sunny Garden hoàn tiền 111%</T.Text>
              <br />
              <sub>Nếu phát hiện hàng giả</sub>
            </div>
          </li> */}
        </ul>
      </Card>
      <Card size="small">
        <ul>
          {/* <li>
            <div className="icon">
              <MenuOutlined />
            </div>
            <div className="des">
              <T.Text>Địa chỉ giao hàng</T.Text>
              <T.Text>90 Nguyen Dinh Chieu, P.DaKao, Q.1, TP.HCM</T.Text>
            </div>
            <div className="action">
              <Button type={'link'}>Sửa</Button>
            </div>
          </li> */}
          <li>
            <div className="icon">
              <MenuOutlined />
            </div>
            <div className="des">
              <T.Text>{lang.paymentMethod}</T.Text>
              <br />
              <T.Text>{lang.payment}</T.Text>
            </div>
            {/* <div className="action">
              <div className="action">
                <Button type={'link'}>Sửa</Button>
              </div>
            </div> */}
          </li>
          {/* <li>
            <div className="icon">
              <MenuOutlined />
            </div>
            <div className="des">
              <T.Text>Ngày giao hàng</T.Text>
              <br />
              <T.Text>Trong 7 ngày từ</T.Text>
              <br />
              <T.Text>4/14/2019 đến 21/4/2019</T.Text>
            </div>
          </li> */}
        </ul>
      </Card>
      <Card size="small">
        <ul>
          <li>
            <div className="icon">
              <MenuOutlined />
            </div>
            <div className="des">
              {/* <T.Text>Liên hệ</T.Text> */}
              <T.Text>{lang.orderingHotline}</T.Text>
              <br />
              <sub>({lang.orderTime})</sub>
            </div>
          </li>
          <li>
            <div className="icon">
              <MenuOutlined />
            </div>
            <div className="des">
              <T.Text>E-mail </T.Text>
              <a href="mailto:sales@sunnygarden.vn">sales@sunnygarden.vn</a>
            </div>
          </li>
        </ul>
      </Card>
      {/* <div>
        <div>button like</div>
        <div>button tro chuyen truc tiep</div>
      </div> */}
    </div>
  )
}

export default deliverySide
