export default {
  vi: {
    'safetyShopping': 'Yên Tâm Mua Hàng Tại',
    'oneToOneExchange': '1 đổi 1 (nếu có lỗi)',
    'paymentMethod': 'Cách thức thanh toán',
    'payment': 'COD, Momo, Air Pay, Viettel Pay, Zalo Pay, Chuyển khoản ngân hàng',
    'orderingHotline': 'Hotline đặt hàng (+84)963.601.086',
    'orderTime': '8-21h cả T7,CN',
  },
  'en-us': {
    'safetyShopping': 'Safe Online Shopping At',
    'oneToOneExchange': 'Refund 100% if plants die/damage during shipping time',
    'paymentMethod': 'Payment Method',
    'payment': 'Western Union, Bank Transfer, COD or Paypal',
    'orderingHotline': 'Hotline for order  (+84)913.497.006',
    'orderTime': '8:00-21:00 Vietnam Time(GMT+7) including Sat. & Sun.'
  },
}
