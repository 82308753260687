import { Button, Card, Col, InputNumber, notification, Row, Typography as T } from 'antd';
import React, { useContext, useState } from 'react';
import gI18n from '../../config/i18n';
import CartContext, { CartMeta } from '../Context/CartContext';
import i18n from './i18n';
import './index.scss';

interface IFeatures {
  feature_item: string
}

export interface IPromotions {
  from_qty: number
  to_qty: number
  promotion_sell_price: number
}

interface IProps {
  locale: string
  productMeta: CartMeta
  features: IFeatures[]
  promotions: IPromotions[]
}

export default function productDetailSide(props: IProps) {
  const [quantity, setQuantity] = useState<number>(1)
  const { addItem, updateQty } = useContext(CartContext);

  const lang = i18n[props.locale]
  const disabled = props.productMeta.maxQty <= 0
  const promotions = props.promotions || []

  function onQtyChange(value: number | undefined) {
    const updQaty = value || 0
    setQuantity(updQaty)
    updateQty(props.productMeta.id, updQaty)
  }

  function handleOnAddToCart() {
    addItem({ ...props.productMeta, qty: quantity })
    notification.success({
      placement: 'bottomRight',
      message: i18n[props.locale].cartSuccessTitle,
      description: i18n[props.locale].cartSuccessDesc,
    })
  }

  promotions.forEach(promotion => {
    if (quantity >= promotion.from_qty && quantity <= promotion.to_qty) {
      props.productMeta.sellPrice = promotion.promotion_sell_price
    }
  })

  const isHidePromotion = promotions.length === 0 || (promotions.length === 1 && !promotions[0].from_qty
    && !promotions[0].to_qty && !promotions[0].promotion_sell_price)

  return (
    <div className="sg-product-detail">
      <T.Title level={4}>{props.productMeta.title}</T.Title>
      <div className="sg-rating">
        {/* <Rate allowHalf={true} disabled={true} defaultValue={2.5} /> <T.Text>389 Ratings | </T.Text> */}
      </div>
      <div className="dashedLine" />
      <div className="sg-status-prod">
        <T.Title level={4} type={'danger'}>
          {gI18n[props.locale].moneySign + props.productMeta.sellPrice.toLocaleString(props.locale)}
        </T.Title>
      </div>
      {props.productMeta.marketPrice > 0 && (
        <>
          <div className="sg-status-prod">
            {lang.moneySaving}:&nbsp;
            <T.Text type="secondary">
              {Math.floor(((props.productMeta.marketPrice - props.productMeta.sellPrice) / props.productMeta.marketPrice) * 100)}%&nbsp;
              <T.Text type="secondary">
                ({gI18n[props.locale].moneySign + (props.productMeta.marketPrice - props.productMeta.sellPrice).toLocaleString(props.locale)})
              </T.Text>
            </T.Text>
          </div>
          <div className="sg-status-prod">
            {lang.marketPrice}:&nbsp;
            <T.Text type="secondary">
              {gI18n[props.locale].moneySign + props.productMeta.marketPrice.toLocaleString(props.locale)}
            </T.Text>
          </div>
        </>
      )}
      <div className="dashedLine" />
      <ul className="sg-list-bullet">
        {props.features.map((feature, index) => (
          <li key={index}>
            <T.Text>{feature.feature_item}</T.Text>
          </li>
        ))}
      </ul>
      {!isHidePromotion && (
        <Card size="small" title={i18n[props.locale].promotionInfo}>
          <ol className="sg-list-numb">
            {promotions.map((item, index) => (
              <li key={index}>
                <T.Text>
                  {lang.from_qty} <strong>{item.from_qty || 0}</strong> {lang.to_qty} <strong>{item.to_qty || 0}</strong>
                </T.Text>
                <T.Text className="promotionPrice" strong={true} type={'danger'}>
                  {gI18n[props.locale].moneySign + (item.promotion_sell_price || 0).toLocaleString(props.locale)}
                </T.Text>
              </li>
            ))}
          </ol>
        </Card>
      )}
      {disabled && (
        <div className="sg-status-prod">
          <T.Text type={'danger'}>{lang.outofStock}</T.Text>
        </div>
      )}
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <span>{lang.qty}:&nbsp;</span>
          <InputNumber
            disabled={disabled}
            min={1}
            // max={props.productMeta.maxQty}
            defaultValue={quantity}
            onChange={onQtyChange}
          />
        </Col>
        <Col span={24}>
          <Button
            className="addToCartBtn"
            size="large"
            type="danger"
            onClick={handleOnAddToCart}
            disabled={disabled}
            ghost={true}
          >
            {lang.addToCart}
          </Button>
        </Col>
      </Row>
    </div>
  )
}
