import { Carousel, Modal } from 'antd'
import Img from 'gatsby-image'
import React, { useState } from 'react'
import './index.scss'

export default function NavSlider({ gallery }: any) {
  const [previewVisible, setPreviewVisible] = useState(false)
  const [nav1, setNav1] = useState(undefined)
  const [nav2, setNav2] = useState(undefined)
  const [nav3, setNav3] = useState<any>(undefined)
  const [currentIndex, setCurrentIndex] = useState(0)

  function handleCancel() {
    setPreviewVisible(false)
  }
  function handlePreview() {
    setPreviewVisible(true)
  }

  // View ============
  const view = {
    asNavFor: nav2,
    ref: (carousel: any) => setNav1(carousel),
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    infinite: true,
    centerMode: false,
    afterChange: (current: number) => setCurrentIndex(current),
  }

  return (
    <div className="sg-nav-slider">
      <Carousel {...view} className="sge-first-slider">
        {renderGallery('fluid', gallery, handlePreview)}
      </Carousel>
      <SmallNav nav1={nav1} setNav2={setNav2} gallery={gallery} />
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <FullView gallery={gallery} nav3={nav3} setNav3={setNav3} currentIndex={currentIndex} />
      </Modal>
    </div>
  )
}

function renderGallery(type: string, gallery: any, handlePreview?: any) {
  return gallery
    .filter((item: any) => item.gallery_image.localFile.childImageSharp != null)
    .map((item: any, index: number) => {
      const src = {
        [type]: item.gallery_image.localFile.childImageSharp[type],
      }
      return (
        <div key={index} className="sg-item">
          <div className="img" onClick={handlePreview ? handlePreview : undefined}>
            <Img {...src} title={item.image_captions.text} alt={item.image_captions.text} />
          </div>
        </div>
      )
    })
}

function SmallNav(props: SmallNavProps) {
  const nav = {
    asNavFor: props.nav1,
    ref: (carousel: any) => props.setNav2(carousel),
    dots: false,
    slidesToShow: 2,
    swipeToSlide: true,
    focusOnSelect: true,
    infinite: true,
    arrows: true,
    variableWidth: false,
    centerMode: true,
  }

  return (
    <div>
      <Carousel {...nav} className="sge-second-slider">
        {renderGallery('fixed', props.gallery)}
      </Carousel>
    </div>
  )
}

function FullView({ nav3, setNav3, currentIndex, gallery }: FullViewProps) {
  const fullView = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    ref: (carousel: any) => setNav3(carousel),
  }

  if (nav3) {
    nav3.goTo(currentIndex)
  }

  return (
    <Carousel {...fullView} className="sge-first-slider">
      {renderGallery('fluid', gallery)}
    </Carousel>
  )
}
