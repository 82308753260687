export default {
  vi: {
    outofStock: 'Hết Hàng',
    qty: 'Số lượng',
    buyNow: 'Mua Ngay',
    addToCart: 'Thêm vào giỏ hàng',
    price: 'Giá',
    marketPrice: 'Giá thị trường',
    moneySaving: 'Tiết kiệm',
    cartSuccessTitle: 'Giỏ Hàng',
    cartSuccessDesc: 'Đã thêm sản phẩm vào giỏ hàng thành công',
    rating: 'Đánh Giá',
    answer: 'Trả lời',
    from_qty: 'Số lượng từ',
    to_qty: 'tới',
    promotionInfo: 'Khuyến mãi',
  },
  'en-us': {
    outofStock: 'Out Of Stock',
    qty: 'Quantity',
    buyNow: 'Buy Now',
    addToCart: 'Add to cart',
    price: 'Price',
    marketPrice: 'Market Price',
    moneySaving: 'Saving',
    cartSuccessTitle: 'Shopping Cart',
    cartSuccessDesc: 'Added product to the shopping cart successfully',
    rating: 'Ratings',
    answer: 'Answered Rating',
    from_qty: 'Quantity from',
    to_qty: 'to',
    promotionInfo: 'Promotion',
  },
}
