import { Col, Divider, Row, Typography as T } from 'antd'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import React from 'react'
import BlockSale from '../components/BlockSale'
import { CartMeta } from '../components/Context/CartContext'
// import RatingProduct from '../components/RatingProduct'
import DeliverSide from '../components/DeliverSide'
import Layout from '../components/Layout'
import MenuCategory from '../components/MenuCategory'
import NavSlider from '../components/NavSlider'
import ProductDetailSide from '../components/ProductDetailSide'
import SEO from '../components/SEO'

const ShopDetailPage = ({
  location,
  data: { prismicShop, saleBlock, blogBlock },
  pageContext: { locale = 'vi', tags },
}: any) => {
  const product = prismicShop.data

  const productMeta: CartMeta = {
    title: product.title.text,
    marketPrice: product.market_price,
    id: prismicShop.id,
    uid: prismicShop.uid,
    currency: product.currency ? product.currency : 'VND',
    qty: 1,
    type: tags[0],
    sellPrice: product.sell_price,
    maxQty: product.quantity,
    imgUrl: product.body[0].items[0].gallery_image.localFile.publicURL,
    weight: product.weight || 1000,
    promotions: product.promotion,
  }

  return (
    <Layout locale={locale} location={location}>
      <SEO
        location={location}
        locale={locale}
        type="product"
        title={product.meta_title}
        keywords={product.meta_keywords}
        description={product.meta_description}
        properties={productMeta}
      />
      <MenuCategory type="shop" locale={locale} tags={tags} />
      <Row gutter={[32, 32]}>
        <Col xs={{ span: 24 }} sm={{ span: 9 }}>
          <NavSlider gallery={product.body[0].items} />
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 9 }}>
          <ProductDetailSide
            locale={locale}
            productMeta={productMeta}
            features={product.features}
            promotions={product.promotion}
          />
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 6 }}>
          <DeliverSide locale={locale} />
        </Col>
      </Row>
      <div className="shop-detail">
        <Divider dashed={true} />
        {/* <T.Title level={4}>{lang.prodInfo}</T.Title> */}
        <T.Paragraph>
          <RichText render={product.description.raw} />
        </T.Paragraph>
      </div>
      <BlockSale locale={locale} products={saleBlock.edges} blog={blogBlock.edges[0]} tags={tags} />
      {/* <div className="container">
        <RatingProduct />
      </div> */}
    </Layout>
  )
}

export default ShopDetailPage

export const pageQuery = graphql`
  query ShopBySlug($uid: String!, $locale: String!, $tags: [String!]!) {
    prismicShop(lang: { eq: $locale }, uid: { eq: $uid }) {
      ...ShopDetailFragment
    }
    saleBlock: allPrismicShop(limit: 10, sort: { fields: [data___product_code, last_publication_date], order: DESC }, filter: { uid: { ne: $uid }, lang: { eq: $locale }, tags: { in: $tags } }) {
      edges {
        node {
          ...IndexSaleBlockFragment
        }
      }
    }
    blogBlock: allPrismicBlogPosts(limit: 1, filter: { lang: { eq: $locale }, tags: { in: $tags } }) {
      edges {
        node {
          ...IndexBlogBlockFragment
        }
      }
    }
  }
`
